<template>
  <v-container fluid>
    <v-btn
      class="mt-4"
      color="primary"
      @click="showAddDialog = true"
    >
      Add Manifest
      <v-icon
        small
        right
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>

    <AddManifestDialog
      :dialog.sync="showAddDialog"
    />

    <DataTable
      ref="dataTable"
      :cols="cols"
      :request-params="{ with: ['vehicle', 'driver'] }"
      default-order-by="created_at"
      path="manifests"
    >
      <template v-slot:item.on_date="{ item }">
        <router-link :to="{ name: 'manifests.show', params: { manifestId: item.id } }">
          {{ $momentDate(item.on_date) }}
        </router-link>
      </template>
      <template v-slot:item.vehicle="{ value }">
        {{ value.name }}
      </template>
      <template v-slot:item.driver="{ value }">
        {{ value.name }}
      </template>
    </DataTable>
  </v-container>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'
import AddManifestDialog from '@/components/manifests/AddManifestDialog'

export default {
  name: 'Manifests',

  components: {
    DataTable,
    AddManifestDialog
  },

  data () {
    return {
      showAddDialog: false,
      cols: [
        { text: 'Date', value: 'on_date' },
        { text: 'Description', value: 'description' },
        { text: 'Vehicle', value: 'vehicle' },
        { text: 'Driver', value: 'driver' },
      ]
    }
  },

  mounted () {
    if (window.Echo) {
      window.Echo.private('manifests')
        .listen('ManifestCreated', () => {
          this.$refs.dataTable.getData()
        })
    }
  }
}
</script>
