<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Add Manifest</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.description"
                  :rules="[v => !!v || 'Short description is required']"
                  label="Short Description"
                  required
                  outlined
                  dense
                  autofocus
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-dialog
                  ref="onDateSelector"
                  v-model="onDateModal"
                  :return-value.sync="form.on_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$moment(form.on_date).format('ddd Do MMM')"
                      :rules="[v => !!v || 'Date is required']"
                      required
                      outlined
                      dense
                      label="Date"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.on_date"
                    :min="$moment().format('YYYY-MM-DD')"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="onDateModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.onDateSelector.save(form.on_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.estimated_started_at"
                  :rules="[v => !!v || 'Estimated start time is required']"
                  :items="availableManifestStartTimes"
                  label="Estimated Start Time"
                  required
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ApiAutoCompleteField
                  v-model="form.vehicle"
                  :requestParams="{ onlyManifestVehicles: true }"
                  :rules="[v => !!v || 'Vehicle is required']"
                  required
                  label="Vehicle"
                  path="vehicles"
                >
                  <template v-slot="{ item: vehicle }">
                    <v-list-item-avatar v-if="vehicle.img_url">
                      <v-avatar size="30" color="primary">
                        <img
                          :src="vehicle.img_url"
                          :alt="vehicle.name">
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title><strong>{{ vehicle.name }}</strong></v-list-item-title>
                      <v-list-item-subtitle v-text="vehicle.vin" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>Capacity <strong>{{ vehicle.max_bikes }}</strong></v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </ApiAutoCompleteField>
              </v-col>
              <v-col>
                <ApiAutoCompleteField
                  v-model="form.driver"
                  label="Driver"
                  path="admin-users"
                >
                  <template v-slot="{ item: driver }">
                    <v-list-item-avatar>
                      <v-avatar size="30" color="primary">
                        <img
                          v-if="driver.profile_img_url"
                          :src="driver.profile_img_url"
                          :alt="driver.name">
                        <strong v-else class="white--text" style="font-size: 10px;">{{ driver.initials }}</strong>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title><strong>{{ driver.name }}</strong></v-list-item-title>
                      <v-list-item-subtitle v-text="driver.email" />
                    </v-list-item-content>
                  </template>
                </ApiAutoCompleteField>
              </v-col>
            </v-row>
          </v-form>

          <FormErrors ref="formErrors" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn :loading="loading" color="primary" text @click="createManifest">Add Manifest <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Manifest created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the manifest.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import ApiAutoCompleteField from '@/components/ApiAutoCompleteField'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'
import HasManifestStartTimes from '@/mixins/HasManifestStartTimes'

export default {
  name: 'AddManifestDialog',

  mixins: [ HasDialog, HasForm, HasManifestStartTimes ],

  components: {
    ApiAutoCompleteField,
    FormErrors,
    SnackBar
  },

  data () {
    return {
      onDateModal: false,
      loading: false,
      error: {},
      form: {
        vehicle: {},
        driver: {},
        on_date: this.$moment().format('YYYY-MM-DD')
      }
    }
  },

  methods: {
    createManifest () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.persist('post', {
          path: 'manifests',
          object: {
            ...this.form,
            ...{
              vehicle_id: this.form.vehicle.id,
              driver_id: this.form.driver.id,
            }
          }
        })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
            this.error = error
          })
          .finally(() => this.loading = false)
      }
    }
  }
}
</script>
